import React, { Fragment, useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CardHeader,
  Card,
  Typography,
  TextField
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import { repeat } from "ramda";
import { useRequestState } from "../../hooks/request";
import { Spinner } from "./components/spinner";
import { NumberInput } from "./components/number-input";
import { getTestedServicesOnly, getCategories } from "./operations";
import { formatCurrency } from "./components/currency";

const useStyles = makeStyles({
  heading: {
    fontSize: "1.3rem",
    fontFamily: "sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: " 0.00938em",
    textAlign: "left"
  },
  dropMenu: {
    width: "100%"
  }
});

export const SelectTestServices = ({ value, onChange, labId, onAllowNext }) => {
  const services = useRequestState(getTestedServicesOnly, []);

  const categories = useRequestState(getCategories, []);

  const [inputText, setInputText] = useState("");

  const inputHandler = e => {
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const filteredServices = services?.value?.filter(service => {
    return service.test.title.toLowerCase().includes(inputText);
  });

  const classes = useStyles();

  useEffect(() => {
    services.fetch(labId);
  }, [labId]);

  useEffect(() => {
    categories.fetch(labId);
  }, [labId]);

  useEffect(() => {
    onAllowNext(value.length);
  }, [value]);

  function handleChange(id, qty) {
    onChange([
      ...value.filter(v => id !== v),
      ...(qty > 0 ? repeat<any[]>(id, qty) : [])
    ]);
  }

  if (services.loading) {
    return <Spinner />;
  }

  return (
    <Card>
      <CardHeader title="Select the tests you want" />
      <TextField
        id="outlined-basic"
        variant="outlined"
        fullWidth
        label="Search"
        onChange={inputHandler}
      />
      {inputText.length > 0
        ? filteredServices.map(service => (
            <List>
              <ListItem key={service.id}>
                <ListItemText
                  primary={`${service?.title || ""} ${service?.test.title ||
                    ""}`}
                  secondary={`${service?.testTat} hrs - ${formatCurrency(
                    service.prices?.[`UGX`],
                    `UGX`
                  )}`}
                />
                <ListItemSecondaryAction>
                  <NumberInput
                    value={value.filter(v => service.id === v).length}
                    onChange={qty => handleChange(service.id, qty)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          ))
        : categories.value.filter(category => category?.services?.length)
            ?.length
        ? categories.value.map(category => (
            <Accordion
              className={classes.dropMenu}
              key={category?.id}
              defaultExpanded={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {category.name}
                </Typography>
              </AccordionSummary>
              {category.services?.map(service => (
                <Fragment>
                  <List>
                    <ListItem key={service.id}>
                      <ListItemText
                        primary={`${service?.title || ""} ${service?.test
                          ?.title || ""}`}
                        secondary={`${service?.testTat} hrs - ${formatCurrency(
                          service.prices?.[`UGX`],
                          `UGX`
                        )}`}
                      />
                      <ListItemSecondaryAction>
                        <NumberInput
                          value={value.filter(v => service.id === v).length}
                          onChange={qty => handleChange(service.id, qty)}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Fragment>
              ))}
            </Accordion>
          ))
        : services.value?.map(service => (
            <Fragment>
              <List>
                <ListItem key={service.id}>
                  <ListItemText
                    primary={`${service?.title || ""} ${service?.test?.title ||
                      ""}`}
                    secondary={`${service?.testTat} hrs - ${formatCurrency(
                      service.prices?.[`UGX`],
                      `UGX`
                    )}`}
                  />
                  <ListItemSecondaryAction>
                    <NumberInput
                      value={value.filter(v => service.id === v).length}
                      onChange={qty => handleChange(service.id, qty)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Fragment>
          ))}
    </Card>
  );
};
