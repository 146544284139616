import React from "react";
import {
  Typography,
  Radio,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon
} from "@material-ui/core";

interface RadioGroupInputArguments {
  label: any;
  value: any;
  onChange: any;
  options: any;
  row?: any;
}

export const RadioGroupInput = ({
  label,
  value,
  onChange,
  options,
  row
}: RadioGroupInputArguments) => {
  return (
    <FormControl variant="filled">
      <Typography variant="caption">{label}</Typography>
      <FormGroup row={row}>
        {options.map(option => (
          <FormControlLabel
            key={option.id}
            control={
              <Radio
                checked={value === option.id}
                onChange={() => {
                  onChange(option.id);
                }}
                name={option.name}
              />
            }
            label={option.name}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export const RadioListInput = ({
  isSelected,
  getValue,
  renderOption,
  onChange,
  options
}) => {
  return (
    <List>
      {options?.map(option => (
        <ListItem
          key={getValue(option)}
          button
          dense
          onClick={() => onChange(getValue(option))}
        >
          <ListItemIcon>
            <Radio
              edge="start"
              checked={isSelected(option)}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          {renderOption(option)}
        </ListItem>
      ))}
    </List>
  );
};

export const CheckboxListInput = ({
  value,
  getValue,
  renderOption,
  onChange,
  options
}) => {
  function handleChange(v) {
    if (value.includes(v)) {
      return onChange(value.filter(d => d !== v));
    }

    return onChange([...value, v]);
  }
  return (
    <List>
      {options?.map(option => (
        <ListItem
          key={getValue(option)}
          button
          dense
          onClick={() => handleChange(getValue(option))}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={value.includes(getValue(option))}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          {renderOption(option)}
        </ListItem>
      ))}
    </List>
  );
};
