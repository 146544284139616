import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { instance } from "../config";

const useStyles = makeStyles({
  logo: {
    display: "flex",
    justifyContent: "center"
  }
});

export const Logo = () => {
  const classes = useStyles();

  const [isComponentMounted, setIsComponentMounted] = useState(false);

  useEffect(() => setIsComponentMounted(true), []);

  if (!isComponentMounted) {
    return null;
  }

  return (
    <div className={classes.logo}>
      <img src={instance?.logo} height={72} alt={instance?.company?.name} />
    </div>
  );
};
