export function formatCurrency(x = 0, currency = "UGX") {
  const withCommas = formatNumber(x);
  return `${currency} ${withCommas}`;
}

export function formatCurrencyRange(min, max, currency = "UGX") {
  return `${currency} ${formatNumber(min)} - ${formatNumber(max)}`;
}

function formatNumber(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
