export function formatCurrency(x: number = 0, currency: string = "UGX") {
  const withCommas = formatNumber(x);
  if (x !== 0) {
    return `${currency} ${withCommas}`;
  }

  return "Free";
}

export function formatCurrencyRange(
  min: number,
  max: number,
  currency = "UGX"
) {
  return `${currency} ${formatNumber(min)} - ${formatNumber(max)}`;
}

function formatNumber(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
