import React from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { Remove, Add } from "@material-ui/icons";

export const NumberInput = ({ value, onChange }) => {
  function handleChange(delta) {
    onChange(value + delta);
  }
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <IconButton onClick={() => handleChange(-1)}>
          <Remove />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography>{value}</Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={() => handleChange(1)}>
          <Add />
        </IconButton>
      </Grid>
    </Grid>
  );
};
