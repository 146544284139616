import React, { useEffect } from "react";
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { RadioGroupInput } from "./components/choice-input";
import { instance } from "../../config";

export const SelectPaymentMethod = ({ value, onChange, onAllowNext }) => {
  useEffect(() => {
    onAllowNext(value?.method);
  }, [value]);

  function handleChange(field, v) {
    onChange({ ...value, [field]: v });
  }

  const options = [
    { id: "cash", name: "Cash" },
    { id: "mobile-money", name: "Mobile Money" },
    { id: "card", name: "Debit/Credit Card" },
    { id: "gt-pay", name: "GT Pay" }
  ];

  const isGTpayEnabled = (optionsDefault: { id: string; name: string }[]) => {
    return instance?.settings?.onlinePayments?.providers?.includes("gt-pay")
      ? optionsDefault
      : (optionsDefault = optionsDefault.filter(
          (el: { id: string; name: string }) => {
            return !["gt-pay"].includes(el.id);
          }
        ));
  };

  return (
    <Card>
      <CardHeader title="How would you like to pay?" />

      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <RadioGroupInput
              label=""
              value={value.method}
              onChange={method => handleChange("method", method)}
              options={isGTpayEnabled(options)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
