import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField
} from "@material-ui/core";

export const CollectContactInfo = ({ value, onChange, onAllowNext }) => {
  useEffect(() => {
    onAllowNext(!!value.email || !!value.phone);
  }, [value]);

  function handleChange(field, v) {
    onChange({ ...value, [field]: v });
  }

  return (
    <Card>
      <CardHeader title="How can we contact you?" />
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              label="Name"
              variant="outlined"
              type="text"
              fullWidth
              value={value.name}
              onChange={e => handleChange("name", e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Phone Number"
              variant="outlined"
              type="tel"
              fullWidth
              value={value.phone}
              onChange={e => handleChange("phone", e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Email"
              variant="outlined"
              type="email"
              fullWidth
              value={value.email}
              onChange={e => handleChange("email", e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
