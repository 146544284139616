import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Typography
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { sum } from "ramda";
import { formatCurrency } from "../../utils/currency";
import { useRequestState } from "../../hooks/request";
import { SelectTestServices } from "./tests";
import { SelectLab } from "./lab";
import { SelectCollectionServices } from "./collection";
import { CollectContactInfo } from "./contact";
import { SelectPaymentMethod } from "./payment";
import { getAllServices, gtpay, verifyTransaction } from "./operations";
import { createOrder } from "./operations";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { useRouter } from "next/router";
import { instance } from "../../config";
import { FlutterwaveConfig } from "flutterwave-react-v3/dist/types";
import { Logo } from "../../components/logo";

export default function OrderForm() {
  const router = useRouter();

  const [activeStep, setActiveStep] = useState(0);
  const [nextAllowed, setNextAllowed] = useState(false);

  const [labId, setLabId] = useState();
  const [tests, setTests] = useState<any[]>([]);
  const [collection, setCollection] = useState<any>({});
  const [payment, setPayment] = useState<any>({});
  const [contact, setContact] = useState<any>({
    name: "",
    phone: "",
    email: ""
  });
  const source = "public";

  const config: FlutterwaveConfig = {
    public_key: instance?.settings?.onlinePayments?.flutterwavePublicKey,
    tx_ref: "temporary",
    amount: payment.amount,
    currency: "UGX",
    payment_options: "card, mobilemoney",
    customer: {
      email: contact.email,
      name: contact.name,
      phonenumber: contact.phone
    },
    customizations: {
      title: instance?.company?.name,
      description: "Order for Test",
      logo: instance?.logo
    }
  };

  const {
    serviceId: collectionServiceId,
    location: location,
    appointment: appointment,
    onsiteCollectionSiteId
  } = collection;

  const order = {
    currency: "UGX",
    labId,
    tests,
    collectionServiceId,
    offsiteLocation: location?.location,
    offsiteAddress: location?.address,
    [location ? "offsiteAppointment" : "onsiteAppointment"]: appointment,
    paymentMethod: payment.method,
    onsiteCollectionSiteId,
    contact,
    source
  };

  const handleFlutterPayment = useFlutterwave(config);

  function handleOrder() {
    createOrder(order).then(savedOrder => {
      if (payment.method === "cash") {
        return router.push(`/orders/${savedOrder.id}`);
      }

      if (["mobile-money", "card"].includes(payment.method)) {
        config.tx_ref = savedOrder.orderNumber;
        config.amount = savedOrder.amount;
        config.payment_options =
          savedOrder.paymentMethod === "card" ? "card" : "mobilemoney";
        handleFlutterPayment({
          callback: response => {
            verifyTransaction(savedOrder.id, response.transaction_id).then(
              () => {
                closePaymentModal();
                router.push(`/orders/${savedOrder.id}`);
              }
            );
          },
          onClose: () => {
            router.push(`/orders/${savedOrder.id}`);
          }
        });
      }

      if (payment.method === "gt-pay") {
        gtpay(savedOrder.id).then(data => {
          return router.push(`${data.url}`);
        });
      }
    });
  }

  const steps = [
    <SelectLab
      value={labId}
      onChange={setLabId}
      onAllowNext={setNextAllowed}
    />,
    <SelectTestServices
      labId={labId}
      value={tests}
      onChange={setTests}
      onAllowNext={setNextAllowed}
    />,
    <SelectCollectionServices
      labId={labId}
      value={collection}
      onChange={setCollection}
      onAllowNext={setNextAllowed}
    />,
    <CollectContactInfo
      value={contact}
      onChange={setContact}
      onAllowNext={setNextAllowed}
    />,
    <SelectPaymentMethod
      value={payment}
      onChange={setPayment}
      onAllowNext={setNextAllowed}
    />
  ];

  return (
    <Box>
      <Box padding="1em" />

      <Logo />

      <Box marginTop="32px" />

      <Grid container justify="center">
        <Grid item md={8} xs={12} lg={6}>
          {steps[activeStep]}
        </Grid>
      </Grid>

      <Box marginTop="128px" />

      <Checkout
        allowNext={nextAllowed}
        steps={steps}
        activeStep={activeStep}
        tests={tests}
        collection={collection}
        payment={payment}
        onSave={handleOrder}
        onChange={setActiveStep}
      />
    </Box>
  );
}

const Checkout = ({
  payment,
  tests,
  collection,
  allowNext,
  activeStep,
  steps,
  onSave,
  onChange
}) => {
  const [total, setTotal] = useState(0);
  const [companyPrices, setCompanyPrices] = useState({});
  const services = useRequestState(getAllServices, []);

  useEffect(() => {
    services.fetch();
  }, []);

  useEffect(() => {
    if (payment.method === "cash") {
      setCompanyPrices({});
    }
  }, [payment]);

  useEffect(() => {
    const selectedServices = [...tests, collection.serviceId];
    const standardPrices = services.value.reduce(
      (all, service) => ({ ...all, [service.id]: service.prices?.[`UGX`] }),
      {}
    );
    const standardTotal = sum(
      selectedServices.map(serviceId => standardPrices?.[serviceId] || 0)
    );
    setTotal(standardTotal);
  }, [tests, collection, companyPrices]);

  const onNext = () => {
    if (allowNext && activeStep < steps.length - 1) {
      return onChange(activeStep + 1);
    }

    if (allowNext && steps.length - 1 === activeStep) {
      return onSave();
    }
  };

  const onBack = () => {
    onChange(activeStep - 1);
  };

  return (
    <AppBar position="fixed" style={{ bottom: 0, top: "auto" }} color="primary">
      <Container>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              startIcon={<KeyboardArrowLeft />}
              onClick={onBack}
              disabled={activeStep === 0}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Box height="60px" display="flex" alignItems="center">
              <Typography variant="h5" align="center">
                {formatCurrency(total)}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={!allowNext || activeStep > steps.length}
              endIcon={<KeyboardArrowRight />}
              onClick={onNext}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};
