import React, { useEffect, useState } from "react";
import {
  ListItemText,
  Grid,
  CardHeader,
  Card,
  CardContent
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { useRequestState } from "../../hooks/request";
import { LocationInput } from "./components/location-input";
import { RadioListInput } from "./components/choice-input";
import { Spinner } from "./components/spinner";
import { getCollectionServices } from "./operations";
import { formatCurrency } from "./components/currency";

export const SelectCollectionServices = ({
  labId,
  value,
  onChange,
  onAllowNext
}) => {
  const [service, setService] = useState<any>();
  const collectionServices = useRequestState(getCollectionServices, []);

  useEffect(() => {
    onAllowNext(
      (service?.collectionType === "offsite" &&
        value.location &&
        value.appointment) ||
        (service?.collectionType === "onsite" && value.appointment)
    );
  }, [value, service]);

  useEffect(() => {
    collectionServices.fetch(labId);
  }, [labId]);

  useEffect(() => {
    const selectedService = collectionServices.value?.find(
      d => d.id === value.serviceId
    );
    setService(selectedService);
  }, [value, collectionServices.value]);

  function handleChange(field, v) {
    onChange({ ...value, [field]: v });
  }

  if (collectionServices.loading) {
    return <Spinner />;
  }

  return (
    <Card>
      <CardHeader title="How would you like us to collect the samples?" />
      <RadioListInput
        isSelected={service => value.serviceId === service.id}
        options={collectionServices.value}
        renderOption={service => (
          <ListItemText
            primary={service.title}
            secondary={`${service.collectionTat} minutes - ${formatCurrency(
              service.prices?.[`UGX`],
              `UGX`
            )}`}
          />
        )}
        getValue={service => service?.id}
        onChange={id =>
          onChange({
            serviceId: id
          })
        }
      />
      <CardContent>
        <Grid container direction="column" spacing={2}>
          {service?.collectionType === "offsite" && (
            <>
              <Grid item>
                <LocationInput
                  value={value.location}
                  onChange={v => handleChange("location", v)}
                />
              </Grid>
              <Grid item>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    label="Appointent Date/Time"
                    inputVariant="outlined"
                    fullWidth
                    value={value.appointment}
                    onChange={date => handleChange("appointment", date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </>
          )}
          {service?.collectionType === "onsite" && (
            <>
              <Grid item>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    label="Appointent Date/Time"
                    inputVariant="outlined"
                    fullWidth
                    value={value.appointment}
                    onChange={date => handleChange("appointment", date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
