import { client } from "../../utils/client";
import { stringify } from "qs";

export function getFacilities() {
  return client
    .get(
      `/public/facilities?${stringify({
        filter: {
          isDisabled: false
        }
      })}`
    )
    .then(response => response.data.data);
}

export function getCategories(labId: string) {
  return client
    .get(
      `/public/categories?${stringify({
        filter: {
          labId
        },
        field: "rank",
        sort: "ASC",
        perPage: 100
      })}`
    )
    .then(response => response.data.data);
}

export function getTestServices(labIds) {
  return client
    .get(
      `/public/tests?${stringify({
        filter: {
          labIds
        },
        perPage: "infinity"
      })}`
    )
    .then(response => response.data.data);
}

export function getCollectionServices(labIds) {
  return client
    .get(
      `/public/services?${stringify({
        filter: {
          labIds,
          type: "collection"
        },
        perPage: "infinity"
      })}`
    )
    .then(response => response.data.data);
}

export function getTestedServicesOnly(labIds) {
  return client
    .get(
      `/public/services?${stringify({
        filter: {
          labIds,
          type: "test"
        },
        perPage: 1000
      })}`
    )
    .then(response => response.data.data);
}

export function getFacility(labId) {
  return client
    .get(`/public/facilities/${labId}`)
    .then(response => response.data);
}

export function getCompanies(labId) {
  return client
    .get(
      `/portal/companies?${stringify({
        filter: { labIds: labId },
        perPage: 1000
      })}`
    )
    .then(response => response.data.data);
}

export function getAccounts() {
  return client.get(`/portal/accounts`).then(response => response.data.data);
}

export function getAllServices(labIds?) {
  return client
    .get(
      `/public/services?${stringify({
        filter: {
          labIds
        },
        perPage: 1000
      })}`
    )
    .then(response => response.data.data);
}

export function gtpay(orderId) {
  return client
    .post(`/public/orders/${orderId}/gtpay`)
    .then(response => response.data);
}

export function gtPaymentReview(paymentDetails) {
  return client
    .post(`/public/orders/gtpay/verify`, { paymentDetails })
    .then(response => response.data);
}

export function createOrder(orderData) {
  return client
    .post(`/public/orders/`, orderData)
    .then(response => response.data.data);
}

export function verifyTransaction(
  orderId: string,
  flutterwaveTransactionId: number
): Promise<void> {
  return client
    .post(`/public/orders/${orderId}/verify`, { flutterwaveTransactionId })
    .then(response => response.data);
}
