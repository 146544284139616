import React, { useEffect } from "react";
import { Card, CardHeader, ListItemText } from "@material-ui/core";
import { useRequestState } from "../../hooks/request";
import { RadioListInput } from "./components/choice-input";
import { Spinner } from "./components/spinner";
import { getFacilities } from "./operations";

export const SelectLab = ({ value, onChange, onAllowNext }) => {
  const facilities = useRequestState(getFacilities);

  useEffect(() => {
    facilities.fetch();
  }, []);

  useEffect(() => {
    onAllowNext(!!value);
  }, [value]);

  if (facilities.loading) {
    return <Spinner />;
  }

  return (
    <Card>
      <CardHeader title="Select A Branch Nearest To You" />
      <RadioListInput
        isSelected={facility => value === facility.id}
        options={facilities.value}
        renderOption={facility => (
          <ListItemText primary={facility.name} secondary={facility.address} />
        )}
        getValue={facility => facility.id}
        onChange={onChange}
      />
    </Card>
  );
};
