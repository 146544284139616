import React from "react";
import { CircularProgress, Box } from "@material-ui/core";

export const Spinner = () => {
  return (
    <Box
      display="flex"
      flex="1 1 auto"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
};
