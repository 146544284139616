import React from "react";
import Head from "next/head";
import OrderForm from "../features/order";
import { instance } from "../config";

export default function Home() {
  return (
    <>
      <Head>
        <title>Online Order | {instance?.company?.name}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <OrderForm />
    </>
  );
}
